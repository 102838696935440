@import 'fonts';
@import "constants";
@import "animations";
@import "media";
@import "slider";

// GENERAL

body {
  font-family: $system-font-stack;
  font-size: $system-font-size;
  color: $system-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
  height: 100vh;
}

.App {
  background: $main-bg;
  height: 100vh;
  overflow: hidden;
}

.main-page-container {
  overflow: auto;
  height: 100vh;
}

// NAV SYSTEM

.nav-container {
  height: 8rem;
  @include print-hidden;
  @include tiny {
    height: 6rem;
  }

  &.single-line {
    height: 3rem;
  }
}

.navbar {
  padding: $navbar-padding;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  width: calc(100% - #{$navbar-margin-horizontal});
  z-index: $layer-navigation;
  transition: bottom 1s ease, top 1s ease;
  pointer-events: none;

  @include print-hidden;

  &.bottom {
    top: unset;
    bottom: 0
  }

  &.top {
    top: 0;
  }

  & > .container-single {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  & > .container-side {
    display: flex;
    flex-direction: column;
    flex-basis: 20%;

    &:first-child {
      align-items: flex-start;
      margin-left: 1rem;
    }

    &:last-child {
      align-items: flex-end;
      margin-right: 1rem;
    }
  }

  & > .container-center {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
  }

  .nav-compact-controls-section {
    display: none;
    margin-left: .6rem;
    @include tiny {
      display: block;
    }
  }

  .nav-compact-buffer {
    display: none;
    @include tiny {
      display: block;
      flex-grow: 2;
    }
  }

  .nav-controls-section {
    @include tiny {
      display: none;
    }
  }

  .nav-typeface-section {
    @include tiny {
      display: none;
    }
  }

  .nav-link-section {

  }
}

.nav-link {
  position: relative;
  pointer-events: all;
  background-color: $main-bg;
  border: none;
  border-radius: $main-border-radius;
  padding: $nav-button-padding-vertical $nav-button-padding-horizontal $nav-button-padding-vertical $nav-button-padding-horizontal;
  font-family: $system-font-stack;
  font-size: $system-font-size;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
    font-style: italic;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: $main-bg;
    border-radius: $main-border-radius;
    box-shadow: $ui-shadow;
  }

  &.clickable {
    cursor: pointer;
    display: block;

    &:hover {
      font-style: italic;
    }
  }

  & > a {
    text-decoration: none;
    color: black;

    &:hover {
      font-style: italic;
    }

    &:focus {
      outline: none;
      font-style: italic;
    }
  }

  &.active {
    color: $main-highlight;

    & > a {
      color: $main-highlight;
    }
  }


  &:focus {
    color: $deep-highlight;
  }

  &.special {
    color: $main-red;
  }
}

.nav-break {
  flex-basis: 100%;
  height: 0;
}

.nav-select-segment {

}

.nav-padded {
  padding: $nav-button-padding-vertical $nav-button-padding-horizontal $nav-button-padding-vertical $nav-button-padding-horizontal;
  box-shadow: $ui-shadow;
  background: white;
  display: flex;
}

.nav-select-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: $main-border-radius;
  box-shadow: $ui-shadow;
  background: white;

  .nav-select-symbol {
    padding: $nav-button-padding-vertical $nav-button-padding-horizontal $nav-button-padding-vertical $nav-button-padding-horizontal;
    color: $main-highlight;
  }

  .nav-select {
    pointer-events: all;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    border: none;
    background: none;
    font-family: $system-font-stack;
    color: $main-highlight;
    font-size: $system-font-size;
    padding: $nav-button-padding-vertical $nav-button-padding-horizontal $nav-button-padding-vertical $nav-button-padding-horizontal;

    &:after {
      content: "A";
    }
  }
}


.nav-horizontal-drawer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  pointer-events: none;
}

.nav-horizontal-segment {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > button {
    margin-right: 2em;
  }

  & > button:last-child {
    margin-right: 0;
  }

  &.nav-cut-select {
    flex-wrap: wrap;
  }
}

.nav-buffer {
  flex-grow: 2;
}

// COOKIE CONSENT

.consent-bar {
  display: flex;
  flex-direction: row;
  animation: 1s ease slidein;
  background: white;
  box-shadow: $ui-shadow;
  z-index: $layer-consent-banner;
  @include print-hidden;
}

.consent-text {
  margin-left: 2vw;
  flex-grow: 2;
  display: flex;
  align-items: flex-start;
  margin-right: .5rem;
}

.consent-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 2vw;

  @include small {
    flex-direction: column;
    align-items: start;
  }

  & > div {
    margin-right: 1em;
    @include small {
      margin-right: 0;
    }
  }
}


// STONE

.stone-container {
  position: fixed;
  transition: margin-right 1s ease;
  touch-action: none;
  user-select: none;
  z-index: $layer-stone;
  @include print-hidden;
}

.bubble-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: $layer-bubble;
  @include print-hidden;
}

.bubble-body {
  margin: .5rem;
  padding: 1rem;
  background-color: black;
  border-radius: 15px;
  color: white;
  font-family: $stone-font-stack;
  font-size: $stone-font-size;
  overflow: auto;
  flex-shrink: 1;
  z-index: $layer-bubble;
  user-select: none;
}

.bubble-spike {
  position: fixed;
  top: 0;
  left: 0;
  //width: 100vw;
  //height: 100vh;
  user-select: none;
  z-index: $layer-bubble;
  @include print-hidden
}

// PAGE LAYOUT
hr {
  border: none;
  height: 1px;
  color: #333;
  background-color: #333;
  width: 100%;
}

.br {
  height: 1.2em;
}

.column-container {
  //display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  white-space: pre-wrap;

  dd {
    margin-bottom: 15px;
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      font-style: italic;
    }
  }
}

.col-50 {
  flex-basis: 50%;
}

//.col-50:first-child {
//  margin-right: .7rem;
//  @include tiny {
//    margin-right: 0;
//  }
//}
//
//.col-50:last-child {
//  margin-left: .7rem;
//  @include tiny {
//    margin-left: 0;
//  }
//}

.big-space {
  width: 100%;
  height: 3rem;
}

dd {
  margin-bottom: 30px;
}

dd:last-child {
  margin-bottom: 0;
}

// Overlays
.overlay-content-wrapper {
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow: -moz-scrollbars-none;

  &.margin-right {
    padding-right: 1rem;
  }

  &::-webkit-scrollbar {
    width: 0 !important
  }


}


.overlay-close-button {
  margin-top: $overlay-margin-vertical;
  //margin-left: 1rem;
  //margin-right: 1rem;

  & > a {
    text-decoration: none;
    color: $system-font-color;
    padding: 1rem;

    &:hover {
      font-style: italic;
    }
  }
}

.overlay-content-container {
  flex-grow: 2;
  margin-top: $overlay-margin-vertical;
  margin-bottom: 5rem;
}

.specimen-svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.specimen-svg {
  width: 90%;

  @include tiny {
    width: 100%;
  }
}

.typeface-image {
  img {
    width: 100%;
  }

  .caption {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.left-overlay-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $layer-overlay;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
  transition: right .5s ease;
}

.right-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-overlay;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
  transition: left .5s ease;
}

.bottom-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-overlay;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: row;
  transition: all .5s ease;
  background: $shop-bg;
}

.overlay-space {
  width: 60vw;
  display: block;

  @include tiny {
    display: none;
  }
}

.overlay-body-left {
  box-shadow: $yellow-shadow;
  background: $main-yellow;
  overflow: auto;
  width: 40vw;
  display: flex;
  flex-direction: row;

  @include tiny {
    width: 100vw;
  }
}

.overlay-body-right {
  box-shadow: $ui-shadow;
  background: $main-bg;
  overflow: auto;
  width: 40vw;
  display: flex;
  flex-direction: row;

  @include tiny {
    width: 100vw;
  }
}

.left-overlay-container.slide-enter {
  right: 100vw;
}

.left-overlay-container.slide-enter-active {
  right: 0;
}

.left-overlay-container.slide-exit {
  right: 0;
}

.left-overlay-container.slide-exit-active {
  right: 100vw;
}

.right-overlay-container.slide-enter {
  left: 100vw;
}

.right-overlay-container.slide-enter-active {
  left: 0;
}

.right-overlay-container.slide-exit {
  left: 0;
}

.right-overlay-container.slide-exit-active {
  left: 100vw;
}

.bottom-overlay-container.slide-enter {
  top: 100vw;
}

.bottom-overlay-container.slide-enter-active {
  top: 0;
}

.bottom-overlay-container.slide-exit {
  top: 0;
}

.bottom-overlay-container.slide-exit-active {
  top: 100vw;
}

.overlay-heading-wrapper {
  display: flex;
  flex-direction: row;
}

.overlay-heading {
  text-transform: uppercase;
}

.character-list {
  margin-top: 12px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.top {
    margin-bottom: 7.5px;
  }

  &.bottom {
    margin-top: 12px;
  }
}

.character-display {
  font-size: 52px;
  text-align: center;
  width: 3.5rem;
  user-select: none;
}

.typeface-animation {
  margin: 1rem
}

.type-specimen-img {
  width: 100%;
}

svg {
  text {
    user-select: none;
  }
}

.ciao-animation-text {
  font-variation-settings: 'wght' 85
}

.tgr-animation-text {
  animation: 3s cubic-bezier(0.76, 0, 0.24, 1) tgr-animation infinite alternate;
  font-variation-settings: 'wght' 65
}

.icon-link-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 30px;

  .icon-link {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.icon-link {
  font-family: $system-font-stack;
  font-size: $system-font-size;
  border: 0;
  color: black;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  background: unset;

  &:hover {
    font-style: italic;
  }

  &:focus {
    outline: none;
    font-style: italic;
  }
}

// Order Form
.order-form {
  background: $main-bg;
}

.form-section {
  display: flex;
  flex-direction: row;
  border-top: $main-border;
  border-right: $main-border;
  border-left: $main-border;
  align-items: center;
  padding: 0;

  &:last-child {
    border-bottom: $main-border;
  }

  & > label {
    padding: $form-padding;
    flex-basis: 40%;
  }


  & > input {
    padding: $form-padding;
    margin: 0;
    flex-basis: 60%;
    border: none;

    &:invalid {
      color: $main-red
    }

    &:focus {
      background: $main-yellow;
      outline: none;
    }
  }

  & > select {
    flex-basis: 60%;
    overflow: hidden;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none;
    font-family: $system-font-stack;
    font-size: $system-font-size;
    background-color: unset;
    width: 100%;
  }

  .form-select-wrapper {
    flex-basis: 60%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;

    .form-select-symbol {
      user-select: none;
      padding: $form-padding;
    }

    select {
      flex-grow: 2;
      padding: $form-padding;
      overflow: hidden;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -ms-appearance: none;
      font-family: $system-font-stack;
      font-size: $system-font-size;
      background-color: unset;
      margin-left: .5rem;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;

      &:focus {
        outline: none;
        background: $main-yellow;
      }
    }
  }

  .segment-label {
    flex-grow: 2;
    user-select: none;
    white-space: pre-wrap;

    &.no-hint{
      margin-left: $form-padding;
      padding: .2rem;
    }
  }

  &.interactive {
    cursor: pointer;

    &:hover{
      background: black !important;
      color: white;

      button {
        color: white;
      }
    }

    &:focus{
      .segment-label{
        font-style: italic;
      }
    }

    &.active {
      background: $main-yellow;
    }
  }

}

.checkbox-toggle {
  width: 1.2em;
  user-select: none;
}


// Shop
.shop-placeholder-text {
  text-align: center;
  height: calc(100% - #{ $overlay-margin-vertical * 2});
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shop-center-text {
  text-align: center;
}

.shop-dual-choice {
  display: flex;
  flex-direction: row;

  .choice-col {
    flex-basis: 33%;

    &.center {
      text-align: center;
    }
  }
}

.shop-outcome-container {
  margin: 2rem;
}

.form-hidden {
  opacity: .2;
  pointer-events: none !important;
  user-select: none;
}

.shop-layout {
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  //height: 100vh;
  @include desktop {
    flex-direction: row;
  }
}

.shop-overlay-bottom {
  position: fixed;
  bottom: 1em;
  left: calc(50vw + 1.2rem + 1px);
  width: calc(50vw - 1.2rem - 1px);

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  transition: bottom .5s ease;

  @include small{
    left: 0;
    width: 100vw;

    a{
      text-align: center;
    }
  }

  &.consent-bar-visible{
    bottom: 4em;
  }
}

.shop-trial-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 4px;
}

.shop-col {
  flex-basis: 50%;
  flex-direction: column;
  padding: $overlay-margin-vertical 1.2rem;
  height: 100%;

  &.left{
    &.funds-provided{
      @include small{
        display: none;
      }
    }
  }

  &.right {
    margin-top: 1rem;
    background: $shop-bg;
    //padding-bottom: 5rem;

    //height: calc(100vh - #{$overlay-margin-vertical + 5rem});


    @include desktop {
      margin-top: 0;
      border-left: 1px solid black;
    }
  }

  @include desktop {
    overflow: auto;
    height: calc(100vh - #{$overlay-margin-vertical *2});
  }
}

.shop-col-title {
  margin-bottom: .5rem;
  display: flex;
  justify-content: space-between;
}

.family-preview {
  font-size: 72px;
  text-align: center;
}

.type-choice-section {
  border-top: $main-border;
  border-right: $main-border;
  border-left: $main-border;
  padding: 0;
  border-bottom: $main-border;
  margin-bottom: 1rem;
  background: white;


  &:last-child {
    margin-bottom: 0;
  }


  .type-choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: $main-border;
    padding: $form-padding;
    user-select: none;
    cursor: pointer;
    //transition: background-color .09s linear;

    &.multiline {
      flex-wrap: wrap;
    }

    &:last-child {
      border-bottom: none;
    }

    &.hoverable {
      &:hover {
        background: black;
        color: white;

        button {
          color: white;
        }

        text {
          fill: white;
        }
      }
    }

    &.selected {
      background: $main-yellow;
    }

    &.disabled {
      cursor: wait;
    }

    &:focus {
      outline: none;
      .type-choice-name{
        font-style: italic;
      }
    }

    &.preselected {
      //animation: highlight-choice 2.5s forwards;
    }

    .type-choice-name {
      flex-grow: 0;
      overflow: auto;
      font-size: $system-font-size;
      user-select: none;
      cursor: pointer;

      &.disabled {
        cursor: wait;
      }
    }

    .choice-hint {
      flex-grow: 2;
      text-align: right;
      opacity: 0.5;
    }

    .type-choice-price {
      animation: 1s fadein ease forwards;

      &.family-override {
        text-decoration: line-through;
      }
    }

    .type-choice-checkbox {
      margin-left: 1rem;
      margin-right: 0;
      flex-shrink: 0;
      transition: opacity .5s ease;
      align-self: flex-start;

      &.disabled {
        opacity: .5;
        cursor: wait;
      }
    }
  }
}

.hidden-checkbox {
  display: none;
}

.custom-quote-menu {
  display: flex;
  flex-direction: column;

  textarea {
    resize: none;
    padding: .2rem;
    font-size: $system-font-size;
    font-family: $system-font-stack;

  }
}


.license-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.license-label {
  flex-basis: 30%;
}

.license-select-dropdown {
  //flex-basis: 70%;
}

.consent-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > label {
    margin-left: 1rem;
    user-select: none;

    & > a {
      color: black;
      text-decoration: none;

      &:hover {
        font-style: italic;
      }

      &:focus {
        outline: none;
        font-style: italic;
      }
    }
  }

  & > .consent-checkbox {
    margin-left: 0;
    cursor: pointer;
  }
}


.single-price-row {
  display: flex;
  flex-direction: row;
}

.spr-descriptor {
  flex-grow: 2;
}

.spr-price {
  flex-grow: 0;

  .price-original{
    text-decoration: line-through;
    color: $main-red;
    margin-right: .5em;
  }

  .price-discount{
    margin-right: .5em;
  }
}

.price-invalid {
  text-decoration: line-through;
}

.coupon-message {
  padding: .2rem;
}

.coupon-warning {
  padding: .2rem;
  color: $main-red;
  text-align: center;
}

.high-reach-note{
  color: $main-red;
}

// Poem
.loading-container:after {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: "-";
  //font-size: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  animation: load 1s infinite alternate;
}

.poetry-heading {
  font-size: 2vh;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
}

.poetry-text {
  margin: 2vw 4vw;
  outline: none;
}

.type-tester {
  font-size: 8vh;
  line-height: 1.1em;
  outline: none;
  margin: 1vw;

  @include tiny {
    font-size: 5vh !important;
  }
}

.poetry-container {
  outline: none;
}

// INTERACTIVITY

input {
  font-family: $system-font-stack;
  font-size: $system-font-size;
}

.link-button {
  border: none;
  background: $main-bg;
  color: $main-highlight;
  font-family: $system-font-stack;
  font-size: $system-font-size;
  padding: .2rem;
}

.icon-button {
  border: none;
  background: none;
  font-family: $system-font-stack;
  font-size: $system-font-size;
  padding: .2rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    font-style: italic;
  }
}

.popup-container {
  position: absolute;
  background: black;
  color: white;
  padding: .25em;
  display: flex;
  flex-direction: row;
  max-width: 300px;

  .popup-close-column {
    border: none;
    background: none;
    padding: 0;
    margin-right: .25em;
    font-family: $system-font-stack;
    font-size: $system-font-size;
    color: white;
    cursor: pointer;
    flex-grow: 0;
    align-self: flex-start;
    user-select: none;

    &:hover {
      font-style: italic;
    }
  }

  .popup-message {
    flex-grow: 2;
  }
}

.typeface-button {
  text-transform: none;
  position: fixed;
  bottom: 5rem;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  line-height: normal;
  z-index: $layer-navigation;
  font-weight: normal;
  transition: bottom .5s ease;
  font-style: normal;

  @include print-hidden;
  @include tiny {
    bottom: 8rem;
  }

  &.lowered {
    bottom: 1rem;
    @include tiny {
      bottom: 1rem;
    }
  }

  & > a {
    border: none;
    background: $main-yellow;
    color: $system-font-color;
    text-decoration: none;
    font-family: $system-font-stack;
    font-size: $system-font-size;
    padding: .3rem .8rem;
    pointer-events: auto;
    border-radius: $main-border-radius;

    &:hover {
      box-shadow: $yellow-shadow;
    }

    &:focus {
      box-shadow: $yellow-shadow;
      outline: none;
    }
  }


}

.inline-link {
  font-family: $system-font-stack;
  font-size: $system-font-size;
  color: $main-highlight;
  text-decoration: none;
}

.line-input-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.25em;

  .line-input {
    border: none;
    background: none;
    border-bottom: $main-border;
    text-align: center;

    &:focus {
      outline: none;
      background: $main-yellow;
    }
  }

}

.link-button-shop {
  width: 100%;
  border: none;
  background: unset;
  color: $main-highlight;
  font-family: $system-font-stack;
  font-size: $system-font-size;
  padding: 0;
  cursor: pointer;
}

.hidden-button {
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.big-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.big-button {
  cursor: pointer;
  position: relative;
  background-color: $main-bg;
  font-family: $system-font-stack;
  font-size: $system-font-size;
  text-align: center;
  padding: 2rem 1rem;

  border: $main-border;
  margin-top: .5rem;
  border-radius: $main-border-radius;
  //width: 100%;


  &:focus {
    outline: none;
    background: $main-yellow;
  }

  &:hover {
    background: black;
    color: white;
  }
}

.size-slider {
  margin-left: 1em;
  width: 5em;
}

.cute-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.cute-button {
  background: unset;
  border: 1px solid black;
  border-radius: 20px;
  font-size: $system-font-size;
  font-family: $system-font-stack;
  padding: 1px 7px 2px;
  color: black;
  margin: .5rem;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
    font-style: italic;
  }

  &:active {
    background-color: black;
    color: $main-yellow;
    opacity: 1;
  }

  &:focus {
    opacity: 1;
    border: 1px solid white;
    outline: none;
  }

  &.enabled {
    background-color: black;
    color: $main-yellow;
    opacity: 1;
  }
}

.legal-text {
  white-space: pre-wrap;
  padding: 1.7rem;

  a {
    color: black;
    text-decoration: none;

    &:hover {
      font-style: italic;
    }
  }
}

.main-page-link {
  margin-right: 1rem;
  pointer-events: all;

  & > a {
    text-decoration: none;
    color: $system-font-color;

    &:hover {
      color: $main-red
    }
  }
}

.info-title {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .5em;
  margin-bottom: 1rem;
}

.info-bullet{
  display: flex;
  .info-bullet-point{
    width: 1.25em;
    flex-grow: 0;
    flex-shrink: 0;
  }
}


.info-card {
  border: 1px solid black;
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .card-title {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .5em;
    margin-bottom: 1rem;
  }

  .card-content {
    white-space: pre-wrap;
  }

  a {
    color: black;

    &:hover {
      font-style: italic;
      text-decoration: none;
    }
  }
}

.info-split{
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: 1rem;

  @include small {
    display: block;
  }
}

.trial-button {
  text-align: center;
}

.loading-animation-wrapper {
  position: static;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .loading-animation:after {

    animation: main-load 1s infinite alternate;

  }
}

.survey-frame {
  width: 100vw;
  height: 100vh;
}

.new-icon {
  color: $main-red;
}

// Inline highlight (for description texts) inserted as text content span tags
.il-hl {
  font-family: "Times New Roman", serif;
}

.promo-pulse{
  animation: promo-pulse-animation 2s ease 2s 1.5 normal forwards;
}

@keyframes promo-pulse-animation{
  0%, 50% {
    transform: scale(1) translateY(0);
  }
  25%, 75% {
    transform: scale(2) translateY(-100%);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}